@import'../default/app.min.css';

.hover-pointer {
    cursor: pointer;
}

.text-darkGrey {
    color: #757575 !important;
}

.login-logo {
    max-width: 278px;
    height: 34px;
    width: 100%;
    margin-bottom: 10px !important;
    margin-left: -8px !important;
}

#header {
    border-bottom: 1px solid #b8c1ca;
}

body {
    background: #ffff;
    font-family: var(--regular-font);
}

.panel-body {
    border: 1px solid #b8c1ca !important;
}

.logo-title {
    font-size: 20px;
    color: #007cba;
    font-weight: 600;
}

.margin-subtitle {
    margin-top: 18px !important;
}

.btn-warning,
.btn-block {
    border-radius: 0 !important;
    color: #fff !important;
    background-color: #348fe2 !important;
    border-color: #348fe2 !important;
}

.btn-success {
    border-radius: 0 !important;
    color: #fff !important;
    background-color: #00acac !important;
    border-color: #00acac !important;
    box-shadow: 0;
}

.btn-primary,
.btn-white,
.btn-info,
.panel-heading,
.dropdown-menu,
.modal-content,
.css-yk16xz-control,
.sweet-alert,
input,
select,
textarea,
.btn-default {
    border-radius: 0 !important;
}

.input-group-text {
    border-radius: 0 !important;
    background: #fff !important;
}

.btn-secondary,
.btn-icon {
    border-radius: 0 !important;
    color: #757575 !important;
    background-color: #fff !important;
    border-color: #9d9d9d !important;
}

.btn-secondary.focus,
.btn-icon.focus,
.btn-icon:hover,
.btn-secondary:hover {
    color: #fff !important;
    background-color: #6c757d !important;
    border-color: #6c757d !important;
}

.btn-block:hover {
    color: #fff !important;
    background-color: #1c75c7 !important;
    border-color: #1c75c7 !important;
}

.fa-star {
    color: #1C75C7 !important;
}

.text-muted {
    color: #333 !important;
}

.btn-success:hover {
    color: #fff !important;
    background-color: #008686 !important;
    border-color: #007979 !important;
}

button:focus:not(:focus-visible) {
    outline: 0 !important;
}

.otp-modal-backdrop{
    opacity: 1 !important;
    background-color: white;
}

@media (max-width:576px) {
    .login-logo {
        max-width: 238px;
        height: 30px;
    }
}