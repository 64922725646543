.hover-not {
  cursor: not-allowed !important;
}
.nav-profile .info .overflow-auto::-webkit-scrollbar {
  width: 1px;
}

.nav-profile .info .overflow-auto::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px transparent; 
  box-shadow: inset 0 0 6px transparent; 
  border-radius: 10px;
}

.nav-profile .info .overflow-auto::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px transparent; 
  box-shadow: inset 0 0 6px transparent; 
  border-radius: 10px;
}

.text-strikethrough{
  text-decoration: line-through;
}
td {
  max-width: 200px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}
.small-header {
  line-height: 15px;
}
.chat-icon {
  width: 50px !important;
}
.chat-container {
  padding: 10px 0 !important;
  max-height: 250px;
  overflow: auto;
}
.modal-body {
  background-color: #e4e7ea;
}
.dropzone {
  margin: 0 0px;
}
.images-container {
  margin: 0 10px;
  max-height: 250px;
  overflow: auto;
}
.maintenance-container .row > [class^="col-"] {
  padding: 2px 0;
}
.widget-card {
  margin: 0 2px;
}
.nav-container .hover-pointer {
  padding: 0;
}
.tab-content {
  padding: 10px 10px !important;
}
.row-space-2 {
  padding: 10px 0;
}
.widget-chat .right .widget-chat-info {
  margin-right: 50px;
}
.widget-chat .widget-chat-body {
  max-height: 300px;
  overflow-y: auto;
}
.fa-star {
  color: gold;
}
.fa-power-off {
  color: #000;
}
.fa-power-off:hover {
  color: #e74c3c;
  cursor: pointer;
}
.edit-pencil {
  position: absolute;
  bottom: 5px;
  right: 5px;
  background: whitesmoke;
  border-radius: 50%;
  cursor: pointer;
  padding: 2px;
  margin-right: 5px;
}
.edit-pencil i {
  color: orange;
}
.image-preview {
  width: 85px;
  height: 90px;
}
.dz-sm-message {
  font-weight: 300 !important;
  color: inherit !important;
  font-size: 25px !important;
  text-align: center !important;
  margin: 15px 0 !important;
}
.user-profile-pic{
  position: relative;
  width: 200px;
}
.user-profile-pic img{
  width: 200px;
}
.profile-pic {
  border-radius: 50%;
  width: 130px;
  height: 130px;
  border: 3px lightgrey solid;
}

.profile-content .panel-body{
  border: none !important
}

.stepper .panel{
  padding: 0;
  margin: 0;
}
.stepper-head {
  font-family: inherit;
}
.stepper-body{
  min-height: 45vh;
}
.col-sm-4.custom-nav-tab,
.col-sm-6.custom-nav-tab {
  padding: 20px 15px;
  font-size: 13px;
  background: #F2F3F4;
  color: grey;
  cursor: pointer;
}
.custom-nav-tab .number {
  padding: 8px;
}
.custom-nav-tab .number > b {
  background-color: lightgray;
  padding: 7px;
  border-radius: 50%;
}
.custom-nav-tab small{
  font-size: 11px;
}
.col-sm-4.custom-nav-tab.active-tab,
.col-sm-6.custom-nav-tab.active-tab{
  background: #348FE2;
  color: white;
}
.custom-nav-tab.active-tab .number b{
  background-color: #3079bd;
}
.custom-nav-tab.active-tab small{
  color: white;
}

/* -----------NOTICE TO VACATE----------- */

.react-datepicker-wrapper{
  width: 100%;
}

.notice-container .progress{
  background-color: white;
}

.sign-container{
  overflow: hidden;
  position: relative;
}

.sign-container .border{
  padding: 0;
}

.sign-container span{
  position: absolute;
  bottom: 10px;
  right: 5px;
}

.stepper-body .form-control{
  margin-left: 15px;
}

/* -----------End of NOTICE TO VACATE---------- */
@media (max-width: 992px) {
  .dz-sm-message {
    font-size: 36px !important;
  }
  .image-preview {
    width: 125px;
    height: 125px;
  }
}
@media (max-width: 575px) {

  .stepper-body .form-control{
    margin-left: 0px;
  }
}

@media (max-width: 576px) {
  .dz-sm-message {
    font-size: 5vw !important;
  }
  .image-preview {
    width: 15vw;
    height: 17vw;
  }
}
@media (max-width: 400px) {

  .dz-sm-message {
    font-size: 4vw !important;
  }
  .image-preview {
    width: 13vw;
    height: 15vw;
  }
}


.pagination > li {
  display: inline-block;
  padding-left: 0;
  list-style: none;
  margin: 0 2px;
}
.pagination > li > a{
  padding: 6px 12px;
  background-color: #fff;
  border-radius: 2px;
}

.pagination > .active > a {
  color: #fff;
  background-color: rgb(99, 146, 218);
}
.pagination > li > a:hover {
  background-color:  rgb(40, 105, 226);
  color: white;
}

.custom-nav-tab.active-tab .stepper-pill{
  background-color: #3079bd;
 }

.custom-nav-tab .stepper-pill {
  background-color: lightgray;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}